import colors from '../../base/colors'

const { transparent } = colors

export default {
  styleOverrides: {
    root: {
      backgroundColor: transparent.main,
    },
  },
}
