import borders from '../../base/borders'
import boxShadows from '../../base/boxShadows'

const { borderRadius } = borders
const { xxl } = boxShadows

export default {
  styleOverrides: {
    paper: {
      borderRadius: borderRadius.lg,
      boxShadow: xxl,
    },

    paperFullScreen: {
      borderRadius: 0,
    },
  },
}
